var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff", height: "100%" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "培训通知",
                },
                on: {
                  "head-save": function ($event) {
                    return _vm.trainingNoticeSaving()
                  },
                  "head-saveBack": function ($event) {
                    return _vm.headSubmit()
                  },
                  "head-startTraining": function ($event) {
                    return _vm.startTraining()
                  },
                  "head-cancel": _vm.headCancel,
                  "head-sign": function ($event) {
                    return _vm.toSee()
                  },
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "培训主题:",
                                    prop: "trainTheme",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        this.type != "other" &&
                                        this.type != "add" &&
                                        this.type != "curriculumCenter",
                                      placeholder: "请输入培训主题",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.form.trainTheme,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "trainTheme", $$v)
                                      },
                                      expression: "form.trainTheme",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "培训时间:",
                                    prop: "trainTimeRange",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      disabled:
                                        this.type != "other" &&
                                        this.type != "add" &&
                                        this.type != "curriculumCenter",
                                    },
                                    model: {
                                      value: _vm.form.trainTimeRange,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "trainTimeRange",
                                          $$v
                                        )
                                      },
                                      expression: "form.trainTimeRange",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "培训类型:",
                                    prop: "courseType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          this.type != "other" &&
                                          this.type != "add" &&
                                          this.type != "curriculumCenter",
                                      },
                                      model: {
                                        value: _vm.form.courseType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "courseType", $$v)
                                        },
                                        expression: "form.courseType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.courseTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: item.dictKey,
                                          attrs: {
                                            label: item.dictValue,
                                            value: item.dictKey,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "培训级别:",
                                    prop: "trainingLeve",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          this.type != "other" &&
                                          this.type != "add" &&
                                          this.type != "curriculumCenter",
                                      },
                                      model: {
                                        value: _vm.form.trainingLeve,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "trainingLeve",
                                            $$v
                                          )
                                        },
                                        expression: "form.trainingLeve",
                                      },
                                    },
                                    _vm._l(
                                      _vm.trainingLeveList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: item.dictKey,
                                          attrs: {
                                            label: item.dictValue,
                                            value: item.dictKey,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "年度培训计划:",
                                    prop: "trainPlan",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        disabled:
                                          this.type != "other" &&
                                          this.type != "add" &&
                                          this.type != "curriculumCenter",
                                      },
                                      on: { change: _vm.handleTrainPlanChange },
                                      model: {
                                        value: _vm.form.trainPlan,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "trainPlan", $$v)
                                        },
                                        expression: "form.trainPlan",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("计划内"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("计划外"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _vm.form.trainPlan == "1"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "计划培训名称:",
                                        prop: "trainingPlanId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择计划培训名称",
                                            disabled:
                                              this.type != "other" &&
                                              this.type != "add" &&
                                              this.type != "curriculumCenter",
                                          },
                                          model: {
                                            value: _vm.form.trainingPlanId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "trainingPlanId",
                                                $$v
                                              )
                                            },
                                            expression: "form.trainingPlanId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.trainingPlans,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.trainMessage,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "备注:",
                                    prop: "trainMessage",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      disabled:
                                        this.type != "other" &&
                                        this.type != "add" &&
                                        this.type != "curriculumCenter",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.trainMessage,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "trainMessage", $$v)
                                      },
                                      expression: "form.trainMessage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.type != "add" && _vm.form.trainingImages.length > 0
                        ? _c(
                            "div",
                            { staticClass: "pictureBox" },
                            [
                              _c("div", { staticClass: "head-text" }, [
                                _vm._v("补充培训记录"),
                              ]),
                              _c("avue-form", {
                                attrs: {
                                  option: _vm.option,
                                  "upload-preview": _vm.rowView,
                                },
                                model: {
                                  value: _vm.form,
                                  callback: function ($$v) {
                                    _vm.form = $$v
                                  },
                                  expression: "form",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "课程信息",
                                name: "courseInformation",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("div", { staticClass: "formTopic" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          "margin-left": "6px",
                                        },
                                      },
                                      [_vm._v("课程信息")]
                                    ),
                                  ]),
                                  _c("onTheJobTraining", {
                                    ref: "onTheJobTraining",
                                    attrs: {
                                      chooseACourseTask: _vm.chooseACourseTask,
                                      trainingTaskList: _vm.trainingTaskList,
                                    },
                                    on: { course: _vm.courseMethod },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "参与培训人员",
                                name: "trainingPersonnel",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "12px" } },
                                [
                                  _c("div", { staticClass: "formTopic" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          "margin-left": "6px",
                                        },
                                      },
                                      [_vm._v("参与培训人员")]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _vm.type != "view"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "margin-top": "6px",
                                                },
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: _vm.handleUserModeOpen,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      新增\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.type != "view"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "margin-top": "6px",
                                                  margin: "10px",
                                                },
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: _vm.testPaperDownload,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      试卷下载\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("grid-head-layout", {
                                    ref: "searchForm",
                                    attrs: {
                                      "search-columns": _vm.noticePersonColumns,
                                    },
                                    on: {
                                      "grid-head-search":
                                        _vm.noticePersonSearch,
                                      "grid-head-empty": _vm.noticePersonRest,
                                    },
                                  }),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.noticePersonTableData,
                                        size: "medium",
                                        height: "calc(100vh - 500px)",
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.handleSelectionChange,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "selection",
                                          width: "55",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "index",
                                          label: "序号",
                                          align: "center",
                                          width: "80",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "userName",
                                          label: "姓名",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "deptName",
                                          label: "组织",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "totalHours",
                                          label: "总课时",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "standardsHours",
                                          label: "达标课时",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "accomplishAccomplish",
                                          label: "完成必修课时",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "type",
                                          label: "培训方式",
                                          align: "center",
                                          formatter: _vm.formatIsCompulsory,
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "isAccomplish",
                                          label: "是否完成",
                                          align: "center",
                                          formatter: _vm.completeOrNot,
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "操作", width: "150" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                scope.row.isAccomplish
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewDetails(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "查看详情\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                scope.row.isAccomplish &&
                                                _vm.type != "view"
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.recordScores(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "成绩上传\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.type != "view"
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDelete(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "删除\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deptShow
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "90%", dialogTitle: "参加培训人员" },
              on: {
                cancel: function ($event) {
                  _vm.deptShow = false
                },
                confirm: _vm.selectAllData,
              },
            },
            [
              _vm.deptShow
                ? _c("userDepartmentSeparationDialog", {
                    ref: "userDepartmentSeparationDialog",
                    attrs: { disabledList: _vm.disabledErpItemList },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.courseDialog
        ? _c(
            "CommonDialog",
            {
              staticClass: "courseSelection",
              attrs: {
                showConfirmBtn: false,
                width: "85%",
                dialogTitle: "课程",
              },
              on: {
                cancel: function ($event) {
                  _vm.courseDialog = false
                },
              },
            },
            [
              _c("publicCurriculumPopUp", {
                ref: "publicCurriculumPopUp",
                on: { curriculumSubmit: _vm.curriculumSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showFileDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showConfirmBtn: false,
                width: "70%",
                dialogTitle: "附件预览",
              },
              on: {
                cancel: function ($event) {
                  _vm.showFileDialog = false
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }